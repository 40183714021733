import { register } from "register-service-worker";

if (process.env.NODE_ENV == "production" && navigator.serviceWorker) {
  register(`${process.env.BASE_URL}serviceWorker.js`, {
    ready() {
      console.log(
        "[SW] App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },

    registered(registration) {
      console.log("[SW] Service worker has been registered.");

      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 60);
    },

    cached() {
      console.log("[SW] Content has been cached for offline use.");
    },

    updatefound() {
      console.log("[SW] New content is downloading...");
    },

    updated(registration) {
      console.log("[SW] New content is available; please refresh.");

      const event = new CustomEvent("updatefound", { detail: registration });

      document.dispatchEvent(event);
    },

    offline() {
      console.log(
        "[SW] No internet connection found. App is running in offline mode."
      );
    },

    error(error) {
      console.error("[SW] Error during service worker registration:", error);
    },
  });

  let refreshing = false;

  navigator.serviceWorker.addEventListener("controllerchange", function () {
    if (refreshing) return;

    window.location.reload();

    refreshing = true;
  });
}
