
import Vue from "vue";
import axios from "axios";
import apiUrl from "@/utils/apiUrl";

export default Vue.extend({
  name: "app",
  components: {
    accessDenied: () => import("@/views/accessDenied.vue"),
    pwaUpdater: () => import("@/components/pwaUpdater.vue"),
  },
  computed: {
    pageAllowed(): boolean {
      return (
        this.$store.state.currentIpAllowed ||
        this.$route.path.startsWith("/download/")
      );
    },
  },
  async mounted() {
    const modeRes = await axios.get(`${apiUrl}/api/mode`);
    console.log(modeRes.data);
    this.$store.commit("setMode", modeRes.data.mode);

    const ipRes = await axios.get(`${apiUrl}/api/ip/allowed`);
    console.log(ipRes.data);
    this.$store.commit("setIpAllowed", ipRes.data.allowed);
    this.$store.dispatch("init");
  },
});
