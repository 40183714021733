import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentIpAllowed: false,
    mode: "PROD",
    snackbar: false,
    pwaRefresh: true,
    sw: {
      install: null,
      update: null,
    } as any,
  },
  mutations: {
    setIpAllowed: (state, val) => {
      state.currentIpAllowed = val;
    },
    setMode: (state, val) => {
      state.mode = val;
    },
    sw: (state, sw) => {
      state.sw = sw;
    },
    snackbar: (state, snackbar) => {
      state.snackbar = snackbar;
    },
    pwaRefresh: (state, pwaRefresh) => {
      state.pwaRefresh = pwaRefresh;
    },
  },
  actions: {
    init: ({ state, commit }) => {
      window.addEventListener("beforeinstallprompt", (e: any) => {
        e.preventDefault();
        commit("sw", { ...state.sw, install: e });
      });

      document.addEventListener("swupdatefound", (e: any) => {
        commit("sw", { ...state.sw, update: e.detail });
      });
    },
    // install: async ({ commit, state }) => {
    //   const response = await state.sw.install!.prompt();
    //   const accepted = response.userChoice.outcome === "accepted";

    //   if (accepted) console.log(`[SW] Installing Geovent Docs...`);

    //   commit("snackbar", false);
    // },
    update: async ({ commit, state }) => {
      console.log(`[SW] Updating content...`);

      state.sw.update.waiting.postMessage("sw:update");

      commit("snackbar", false);
    },
  },
});
