import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/home.vue"),
  },
  {
    path: "/setup",
    name: "setup",
    component: () =>
      import(/* webpackChunkName: "setup" */ "../views/setup.vue"),
  },
  {
    path: "/files",
    name: "files",
    component: () =>
      import(/* webpackChunkName: "files" */ "../views/files.vue"),
  },
  {
    path: "/items",
    name: "items",
    component: () =>
      import(/* webpackChunkName: "items" */ "../views/items.vue"),
  },
  {
    path: "/bundles",
    name: "bundles",
    component: () =>
      import(
        /* webpackChunkName: "onlyWorksWithBC" */ "../views/onlyWorksWithBC.vue"
      ),
  },
  {
    path: "/download/:type/:id",
    name: "download",
    component: () =>
      import(/* webpackChunkName: "download" */ "../views/download.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
